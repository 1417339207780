import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {concat, includes, isArray, isNumber, isString, join, map, slice, without, words} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet-async';

import Button from 'components/Button';
import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SearchHit from 'components/Search/Hit';
import SearchPagination from 'components/Search/Pagination';
import {getConnectionsFromPinnedResources, getHitsFromPinnedResources} from 'components/Search/utils';
import KyError from 'components/errors/KyError';
import Link from 'components/links/Link';
import Sticky from 'components/shared/sticky';
import useAppContext from 'conf/AppContext';
import getYOffset from 'helpers/sticky';


const entityFilters = [
  {name: 'Chemical', type: 'chemical'},
  {name: 'Gene', type: 'gene'},
  {name: 'Named Allele', type: 'haplotype', indent: true},
  {name: 'Variant', type: 'variant', indent: true},
  {name: 'Phenotype', type: 'disease'},
];
const annotationFilters = [
  {name: 'Clinical Guideline Annotation', type: 'GuidelineAnnotation'},
  {name: 'Drug Label Annotation', type: 'LabelAnnotation'},
  {name: 'Clinical Annotation', type: 'ClinicalAnnotation'},
  {name: 'Variant Annotation', type: 'VariantAnnotation'},
  {name: 'Pathway', type: 'Pathway'},
  {name: 'VIP', type: 'VipGene'},
  {name: 'Literature', type: 'Literature'},
];
// restructure annotationFilters for small screens
const annotationFiltersSmall1 = [
  {name: 'Clinical Guideline Annotation', type: 'GuidelineAnnotation'},
  {name: 'Clinical Annotation', type: 'ClinicalAnnotation'},
  {name: 'Pathway', type: 'Pathway'},
  {name: 'Literature', type: 'Literature'},
];
const annotationFiltersSmall2 = [
  {name: 'Drug Label Annotation', type: 'LabelAnnotation'},
  {name: 'Variant Annotation', type: 'VariantAnnotation'},
  {name: 'VIP', type: 'VipGene'},
];


const didYouMeanPropTypes = {
  matches: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    canonicalName: PropTypes.string.isRequired,
  })).isRequired,
};
function DidYouMean({matches}) {

  if (!matches?.length) {
    return '';
  }
  return (
    <>
      <p>Did you mean:</p>
      <ul>
        {map(matches, (match) => {
          const cname = match.name === match.canonicalName ? '' : ` (alternate name for ${match.canonicalName})`;
          const key = `${match.name}-${match.canonicalName}`;
          return (
            <li key={key}>
              <Link href={`/search?query=${match.name}`}>{match.name}</Link>{cname}
            </li>
          );
        })}
      </ul>
    </>
  );
}
DidYouMean.propTypes = didYouMeanPropTypes;


const propTypes = {
  /**
   * `query` param from query string.
   */
  query: PropTypes.string,
  /**
   * `pinned` param from query string.  Gets translated into `pinnedHits`.
   */
  pinned: PropTypes.string,
  /**
   * `type` param from query string.
   */
  types: PropTypes.arrayOf(PropTypes.string),
  /**
   * `offset` param from query string.
   */
  offset: PropTypes.number,
};
/**
 * Renders the search (results) page.
 */
export default function SearchPage({query, pinned, types = [], offset = 0}) {
  const appContext = useAppContext();
  const searchBar = useSearchBar();
  const [showFilter, setShowFilter] = React.useState(false);
  const [results, setResults] = React.useState(null);
  const [error, setError] = React.useState(null);

  const componentIsMounted = useRef(true);
  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);


  const cleanQuery = query || '';
  const pinnedHits = getHitsFromPinnedResources(pinned);
  let typeFilters = [];
  if (isArray(types)) {
    typeFilters = types;
  } else if (isString(types)) {
    typeFilters = [types];
  }
  const cleanOffset = isNumber(offset) ? offset : 0;
  const typesKey = join(typeFilters);

  useEffect(() => {
    searchBar.setState({
      pinnedHits,
      query: cleanQuery,
      typeFilters,
      origPinnedHits: pinnedHits,
    });

    const load = async () => {
      try {
        const rez = await appContext.api.post('site/ftSearch', {
          json: {
            query: cleanQuery,
            connections: getConnectionsFromPinnedResources(pinned),
            objCls: typeFilters,
            from: cleanOffset,
          },
          timeout: false,
          parseJson: true,
        });
        setResults({
          query: cleanQuery,
          offset: cleanOffset,
          data: rez.data,
        });
      } catch (kyError) {
        setError(<KyError kyError={kyError} />);
      }
    };
    if (cleanQuery) {
      // noinspection JSIgnoredPromiseFromCall
      load();
    } else {
      setResults({
        query: cleanQuery,
        offset: cleanOffset,
        data: {},
      });
    }
  }, [cleanQuery, pinned, typesKey, cleanOffset]);


  const updateFilters = (event) => {
    const {value, checked} = event.target;
    const newTypes = checked
      ? concat(typeFilters, value)
      : without(typeFilters, value);

    const newState = {
      pinnedHits,
      query: cleanQuery,
      typeFilters: newTypes,
      origPinnedHits: pinnedHits,
    };
    searchBar.setState(newState);
    appContext.redirect(searchBar.generateSearchUrl(newState));
  };


  const renderFilter = (filter) => {
    const inputId = `${filter.type}ChkControl`;
    return (
      <div className={clsx('searchFilter__type', {'searchFilter__type--indent': filter.indent})} key={filter.type}>
        <label htmlFor={inputId}>
          <input
            id={inputId}
            type="checkbox"
            value={filter.type}
            checked={includes(typeFilters, filter.type)}
            onChange={updateFilters}
          />
          &nbsp;{filter.name}
        </label>
      </div>
    );
  };

  const toggleSmallFilter = () => {
    setShowFilter(!showFilter);
  };

  const renderSmallFilters = () => (
    <div className="smallSearchFilter">
      <Button
        className="btn btn-outline-secondary"
        actionHandler={toggleSmallFilter}
        aria-controls="smallSearchTypeFilters"
        aria-expanded={showFilter}
      >
        <FontAwesomeIcon icon={['far', 'filter']} />
        Filter by Type{typeFilters.length > 0 ? ` (${typeFilters.length})` : ''}
        <FontAwesomeIcon icon={['fas', `caret-${showFilter ? 'up' : 'down'}`]} />
      </Button>
      <div
        id="smallSearchTypeFilters"
        className={clsx('smallSearchFilter__types', {'smallSearchFilter__types--show': showFilter})}
      >
        <div className="row">
          <div className="col"><header>Entity Types</header></div>
          <div className="col"><header>Annotation Types</header></div>
          <div className="col" />
        </div>
        <div className="row">
          <div className="col">
            {map(entityFilters, renderFilter)}
          </div>
          <div className="col">
            {map(annotationFiltersSmall1, renderFilter)}
          </div>
          <div className="col">
            {map(annotationFiltersSmall2, renderFilter)}
          </div>
        </div>
      </div>
    </div>
  );
  
  const renderEmpty = (didYouMean) => (
    <>
      {renderSmallFilters()}
      <div className="empty">
        <p>
          No results matched your query.
        </p>
        <DidYouMean matches={didYouMean} />
        {
          pinnedHits?.length > 0 && typeFilters.length > 0 &&
          (
            <p>
              Reminder: type filters only apply to search terms and not to pinned items.
            </p>
          )
        }
      </div>
    </>
  );

  const renderResults = () => {
    const {hits, limit, hasMore} = results.data;

    const makeQueryList = (q) => {
      const wordArray = map(words(q, /\S+/g), (w) => `"${w}"`);
      if (wordArray.length === 1) {
        return `"${q}"`;
      } else if (wordArray.length === 2) {
        return `${wordArray[0]} or ${wordArray[1]}`;
      } else {
        const mostWords = slice(wordArray, 0, wordArray.length - 1);
        return join(mostWords, ', ') + ', or ' + wordArray[wordArray.length - 1];
      }
    };

    const queryWords = makeQueryList(results.query);
    return (
      <>
        {renderSmallFilters()}
        <p>
          These are the matches for {queryWords} in the title, name, alternate name or
          description. Pathways are also matched based on their components.
        </p>
        <SearchPagination hasMore={hasMore} startIndex={cleanOffset} numOnPage={hits.length} />
        <div>
          {map(hits, (hit) => <SearchHit key={hit.id} hit={hit} />)}
        </div>
        <SearchPagination
          hasMore={hasMore}
          startIndex={cleanOffset}
          resultsPerPage={limit}
          numOnPage={hits.length}
          className="mt-3"
        />
      </>
    );
  };


  const title = query
    ? `Search results for "${query}"`
    : 'Search';

  let body = <Loading />;
  if (error) {
    body = error;
  } else if (results) {
    if (!query) {
      body = <p>Please supply something to search for.</p>;
    } else if (results.data.hits.length === 0) {
      body = renderEmpty(results.data.didYouMean);
    } else {
      body = renderResults();
    }
  }

  return (
    <div className="searchPage">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <Sticky top={getYOffset(15)}>
              <div className="searchFilter">
                <h4>Filter by Type</h4>
                <section>
                  <header>Entity Types</header>
                  {map(entityFilters, renderFilter)}
                </section>
                <section>
                  <header>Annotation Types</header>
                  {map(annotationFilters, renderFilter)}
                </section>
              </div>
            </Sticky>
          </div>
          <div className="col-sm-9">
            {body}
          </div>
        </div>
      </div>
    </div>
  );
}
SearchPage.propTypes = propTypes;
