import {useState} from 'react';
import {toast} from 'react-toastify';

import WaitingButton from 'components/Button/Waiting';
import Loading from 'components/Loading';
import PageContent from 'components/Page/Content';
import useEditContext from 'components/edit/EditContext';
import KyError from 'components/errors/KyError';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const PAGE_ID = 'ampAllelesToTest';


export default function AmpAllelesToTestPage() {
  const appContext = useAppContext();
  const editContext = useEditContext();

  const {response, error} = useGet(`data/page/${PAGE_ID}`, {
    searchParams: {
      view: 'min',
    },
    cacheResponse: !appContext.isPreview,
  });

  let content = <Loading />;
  if (response) {
    let updateControl;
    if (editContext.isEditMode) {
      updateControl = <UpdateAmpTiersButton />;
    }
    content = (
      <PageContent
        id={PAGE_ID}
        html={response.data?.markdown?.html}
        fullPage={true}
        extraControls={updateControl}
      />
    );
  } else if (error) {
    content = <KyError kyError={error} />;
  }

  return (
    <ReadableWidthTemplate
      title="AMP's Minimum Sets of Alleles for PGx Testing"
      titleJsx={<>AMP&apos;s Minimum Sets of Alleles<br />for PGx Testing</>}
    >
      {content}
    </ReadableWidthTemplate>
  );
}


function UpdateAmpTiersButton() {
  const appContext = useAppContext();
  const [isUpdating, setUpdating] = useState(false);

  const update = async () => {
    setUpdating(true);
    try {
      const rez = await appContext.api.put('curation/ampTiers/_update', {
        timeout: false,
        parseJson: true,
      });
      toast.success(rez.data.message);
    } catch (kyError) {
      appContext.toastError(<KyError kyError={kyError} />);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <WaitingButton actionHandler={update} waiting={isUpdating}>
      Update AMP Tiers
    </WaitingButton>
  );
}
