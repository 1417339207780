// noinspection JSCheckFunctionSignatures

import {lazy} from 'react';

import Browse from 'components/Browse';
import ClinicalAnnotationPage from 'components/ClinicalAnnotation/Page';
import HomePage from 'components/Home';
import Page from 'components/Page';
import SearchPage from 'components/Search/Page';
import VariantAnnotationPage from 'components/VariantAnnotation/Page';
import {parseSearchParams} from 'components/links/utils';
import NotFound from 'components/shared/error/not_found';
import ServerError from 'components/shared/error/server_error';
import App from 'conf/app';
import {getBaseUrl, normalizeType} from 'conf/types';
import {asArray} from 'helpers/util';
import AboutUs from 'pages/AboutUs';
import AmpAllelesToTestPage from 'pages/AmpAllelesToTest';
import ChemicalPage from 'pages/Chemical';
import ClinicalAnnotationsPage from 'pages/ClinicalAnnotations';
import CombinationPage from 'pages/Combination';
import DataAnnotationPage from 'pages/DataAnnotation';
import DataSourcesPage from 'pages/DataSources';
import DiseasePage from 'pages/Disease';
import DownloadsPage from 'pages/Downloads';
import ErrorPage from 'pages/Error';
import GoneErrorPage from 'pages/Error/Gone';
import FdaLabelAnnotationsPage from 'pages/FdaLabelAnnotations';
import FdaPgxAssociationTablePage from 'pages/FdaPgxAssociations';
import FeedbackPage from 'pages/Feedback';
import GenePage from 'pages/Gene';
import GuidelinePage from 'pages/Guideline';
import GuidelineAnnotationPage from 'pages/GuidelineAnnotation';
import GuidelineAnnotationsPage from 'pages/GuidelineAnnotations';
import HaplotypePage from 'pages/Haplotype';
import LabelPage from 'pages/LabelAnnotation';
import LabelAnnotationsPage from 'pages/LabelAnnotations';
import LabelApplicationDiff from 'pages/LabelApplicationDiff';
import LinkAnnotationPage from 'pages/LinkAnnotation';
import LiteraturePage from 'pages/Literature';
import MarkdownSyntaxPage from 'pages/MarkdownSyntax';
import PathwayPage from 'pages/Pathway';
import PathwaysPage from 'pages/Pathways';
import PediatricTagsPage from 'pages/PediatricTags';
import TestingPage from 'pages/PgxTesting';
import PrescribingInfo from 'pages/PrescribingInfo';
import Reset from 'pages/Reset';
import SubmissionPage from 'pages/Submission';
import VariantPage from 'pages/Variant';
import VariantAnnotationsPage from 'pages/VariantAnnotations';
import VersionsPage from 'pages/Versions';
import VipPage from 'pages/Vip';
import VipList from 'pages/Vips';
import WhatIsPharmacogenomicsPage from 'pages/WhatIsPharmacogenomics';
import WhatIsPharmgkbPage from 'pages/WhatIsPharmgkb';
import FdaBiomarkerPage from 'pages/edit/FdaBiomarker';
import FdaBiomarkerDiffPage from 'pages/edit/FdaBiomarker/diff';
import GenotypePage from 'pages/genotype';
import GenotypeResultsPage from 'pages/genotypeResults';
import PgxPaperTypesPage from 'pages/help/PgxPaperTypes';
import PgkbPublicationsPage from 'pages/pgkbPublications';
import AlreadySignedIn from 'pages/user/AlreadySignedIn';
import Login from 'pages/user/Login';
import Logout from 'pages/user/Logout';
import RequestPasswordReset from 'pages/user/RequestPasswordReset';
import ResetPassword from 'pages/user/ResetPassword';
import UpdatePassword from 'pages/user/UpdatePassword';
import RedirectAccessionId from 'routes/RedirectAccessionId';
import RedirectHgnc from 'routes/RedirectHgnc';
import RedirectLiteratureAnnotation from 'routes/RedirectLiteratureAnnotation';
import RedirectPmid from 'routes/RedirectPmid';
import RedirectRsid from 'routes/RedirectRsid';
import RedirectRxnorm from 'routes/RedirectRxnorm';
import BaseRouter from 'routes/base';
import VipTier2List from 'pages/Vips/tier2';

// curation links
const EditProfile = lazy(() =>
  import(/* webpackChunkName: "curation" */'pages/user/EditProfile'));


export default BaseRouter.extend({
  initialize() {
    // Unmatched (404)
    this.route('*unmatched', 'unmatched');

    // Trailing Slash Redirect
    this.route(/(.*)\/+$/, 'trailingSlashRedirect');

    // Homepage
    this.route('', 'home');

    // Accession Objects
    this.route('accession/:id', 'genericAccession');

    this.route('chemical/:id(/:section)(/:sectionId)(?:qs)', 'chemical');
    this.route('clinicalAnnotation/:id', 'clinicalAnnotation');
    this.route('combination/:id(/:section)', 'combination');
    this.route('dataAnnotation/:id', 'dataAnnotation');
    this.route('disease/:id(/:section)(?:qs)', 'disease');
    this.route('gene/:id(/:section)(?:qs)', 'gene');
    this.route('guideline/:id(/:section)', 'guideline');
    this.route('guidelineAnnotation/:id(/:section)', 'guidelineAnnotation');
    this.route('haplotype/:id(/:section)(?:qs)', 'haplotype');
    this.route('hgnc/:id', 'hgnc');
    this.route('labelAnnotation/:id', 'labelAnnotation');
    this.route('linkAnnotation/:id', 'linkAnnotation');
    this.route('literature/:id(/:section)(?:qs)', 'literature');
    this.route('literatureAnnotation/:id', 'literatureAnnotation');
    this.route('pathway/:id(/:section)(?:qs)', 'pathway');
    this.route('pmid/:id', 'pmid');
    this.route('rsid/:id', 'rsid');
    this.route('rxnorm/:id', 'rxnorm');
    this.route('submission/:id', 'submission');
    this.route('variant/:id(/:section)(?:qs)', 'variant');
    this.route('variantAnnotation/:id', 'variantAnnotation');
    this.route('vip/:id(/:section)', 'vipGene');

    // Search
    this.route('search(?:qs)', 'search');

    // Lists
    this.route('annotatedDrugs(?:qs)', 'browseAnnotatedDrugs');
    this.route('clinicalAnnotations', 'clinicalAnnotationsIndex');
    this.route('fda', 'fda');
    this.route('fdaLabelAnnotations', 'fdaLabelAnnotations');
    this.route('fdaPgxAssociations', 'fdaPgxAssociations');
    this.route('genotype(?:qs)', 'genotype');
    this.route('genotypeResults(?:qs)', 'genotypeResults');
    this.route('guidelineAnnotations', 'dosingGuidelines');
    this.route('labelAnnotations', 'labelAnnotations');
    this.route('pathways', 'pathwayList');
    this.route('pediatricSummaryDrugs(?qs)', 'browsePediatricSummaryDrugs');
    this.route('pgkbPublications', 'pgkbPublications');
    this.route('prescribingInfo', 'prescribingInfo');
    this.route('testing', 'testing');
    this.route('variantAnnotations', 'variantAnnotationsIndex');
    this.route('vips', 'vipList');
    this.route('vips/tier2', 'vipTier2List');

    this.route('pediatric/automatedAnnotations', 'pediatricAutomatedAnnotations');
    this.route('pediatric/chemicals', 'pediatricChemicals');
    this.route('pediatric/clinicalAnnotations', 'pediatricClinicalAnnotations');
    this.route('pediatric/dashboard(/:section)', 'pediatricDashboard');
    this.route('pediatric/guidelineAnnotations', 'pediatricGuidelineAnnotations');
    this.route('pediatric/labelAnnotations', 'pediatricLabelAnnotations');
    this.route('pediatric/literature', 'pediatricLiterature');
    this.route('pediatric/variantAnnotations', 'pediatricVariantAnnotations');
    this.route('pediatricTags', 'pediatricTags');

    // Sign up, sign in, and related routes
    this.route('user/login(?*qs)', 'login');
    this.route('user/logout', 'logout');
    this.route('user/registration/step1', 'gone');
    this.route('user/registration/step2', 'gone');
    this.route('user/registration/step3', 'gone');
    this.route('user/resetPassword(/:userId/:token)', 'forgotPassword');
    this.route('user/edit', 'editProfile');
    this.route('user/updatePassword', 'updatePassword');

    // Static Pages
    this.route('acknowledgements', 'dataSources');
    this.route('about', 'about');
    this.route('ampAllelesToTest', 'ampAllelesToTest');
    this.route('dataSources', 'dataSources');
    this.route('downloads', 'downloads');
    this.route('feedback(?:qs)', 'feedback');
    this.route('help/pgxPaperTypes', 'pgxPaperTypes');
    this.route('licensing', 'licensing');
    this.route('markdownSyntax', 'markdownSyntax');
    this.route('page/:key', 'staticPage');
    this.route('versions', 'versions');
    this.route('whatIsPharmacogenomics(/:section)', 'pharmacogenomics');
    this.route('whatIsPharmgkb(/:section)', 'outreach');

    // misc routes
    this.route('reset', 'reset');
    this.route('labelApplication/:id/diff', 'labelApplicationDiff');

    // dev routes
    this.route('dev', 'development');
    this.route('dev/apiUsers(?:qs)', 'apiUsers');
    this.route('curation', 'curation');
    this.route('curation/reports/survey-results', 'surveyReport');
    this.route('curation/reports/listClinicalAnnotations', 'listClinicalAnnotations');
    this.route('curation/reports/chemical-stats', 'chemicalStats');
    this.route('curation/reports/phenotype-stats', 'phenotypeStats');
    this.route('curation/nlp/missingVariants', 'missingVariants');
    this.route('curation/nlp/missingVariant/(:rsid)', 'missingVariant');
    this.route('curation/cpic', 'cpicPage');
    this.route('curation/hgvsValidation', 'hgvsValidation');
    this.route('curation/stats/variantAnnotations', 'variantAnnotationsStats');
    this.route('curation/reports/listVariantAnnotations', 'listVariantAnnotations');
    this.route('curation/litSuggest', 'litSuggest');
    this.route('curation/unapprovedAnnotations', 'listUnapprovedAnnotations');
    this.route('curation/recentAnnotations', 'listRecentAnnotations');
    this.route('curation/stats/gsi', 'gsiStats');
    this.route('curation/literature/search', 'curationLiteratureSearch');

    // edit routes
    this.route('edit/clinicalAnnotation', 'editClinAnn');
    this.route('edit/clinicalAnnotation/(:id)', 'editClinAnn');
    this.route('edit/clinicalAnnotation/duplicate', 'duplicateCaReport');
    this.route('edit/clinicalAnnotation/haplotypes', 'haplotypeCaReport');
    this.route('edit/clinicalAnnotation/missing', 'missingClinical');
    this.route('edit/clinicalAnnotation/needsUpdating', 'clinicalAnnotationNeedsUpdating');
    this.route('edit/clinicalAnnotation/scores', 'clinicalAnnotationScores');
    this.route('edit/dataAnnotation/(:id)', 'editDataAnnotation');
    this.route('edit/dataAnnotation(?:qs)', 'newDataAnnotation');
    this.route('edit/dataAnnotations(?:qs)', 'listDataAnnotations');
    this.route('edit/linkAnnotation/(:id)', 'editLinkAnnotation');
    this.route('edit/phenoconversions', 'phenoconversionListEdit');
    this.route('edit/phenoconversion', 'phenoconversionNew');
    this.route('edit/phenoconversion/(:id)', 'phenoconversionEdit');
    this.route('edit/vip/:id', 'editVip');
    this.route('edit/vip?targetId=:targetId', 'newVip');
    this.route('edit/variantAnnotation(?:qs)', 'createVarAnn');
    this.route('edit/variantAnnotation/(:id)', 'editVarAnn');
    this.route('edit/guideline/(:id)', 'guidelineTool');
    this.route('edit/guideline', 'guidelineTool');
    this.route('edit/guidelines', 'guidelineList');

    this.route('edit/guidelineAnnotations', 'guidelineAnnotationList');
    this.route('edit/guidelineAnnotation/(:guidelineId)', 'guidelineAnnotationTool');

    this.route('edit/guidelineAnnotation/(:id)/recommendations', 'guidelineAnnotationRecEdit');
    this.route('edit/guidelineAnnotation/(:id)/recommendations/(:selectedChemicalId)', 'guidelineAnnotationRecEdit');
    this.route('edit/labelAnnotation/(:id)/recommendations', 'labelAnnotationRecEdit');

    this.route('edit/labelAnnotations', 'labelEditList');
    this.route('edit/labelAnnotation/(:guidelineId)', 'labelTool');
    this.route('edit/fdaBiomarker', 'fdaBiomarker');
    this.route('edit/fdaBiomarker/diff', 'fdaBiomarkerDiff');

    this.route('edit/linkableResources', 'editLinkableResources');
    this.route('edit/literature/(:id)', 'editLiterature');
    this.route('edit/literature/(:id)/relatedInPaper', 'editLiteratureRelatedInPaper');
    this.route('edit/literature/(:id)/variantAnnotations', 'editLiteratureVariantAnnotations');
    this.route('edit/literature/nonPubMed', 'editNonPubMedLiteraturePage');
    this.route('edit/literature/labelDocuments', 'editLabelDocumentLiteraturePage');

    this.route('edit/genePhenotype/:id', 'genePhenotypeEdit');
    this.route('edit/genePhenotype(?:qs)', 'genePhenotypeNew');
    this.route('edit/haplotypeSets', 'haplotypeSetListEdit');
    this.route('edit/haplotypes/:id', 'haplotypeListEdit');
    this.route('edit/diplotypeFunction/:id(?:qs)', 'diplotypeFunctionEdit');

    this.route('edit/page/:id', 'pageToolEdit');
    this.route('edit/page', 'pageToolNew');
    this.route('edit/page(?:qs)', 'pageToolNew');
    this.route('edit/pages(?:qs)', 'pageListEdit');

    this.route('edit/ontologyResources', 'microdictionariesList');
    this.route('edit/ontology/:id', 'microdictionariesTool');
    this.route('edit/pharmgkbTags', 'listPharmgkbTags');
    this.route('edit/pharmgkbTags/:id', 'listPharmgkbTagged');
    this.route('edit/import/chemical', 'importChemical');
    this.route('edit/import/gene', 'importGene');
    this.route('edit/import/variant', 'importVariant');
    this.route('edit/import/phenotype', 'importPhenotype');

    this.route('edit/variant/:id', 'variantEditForm');
    this.route('edit/alleles/:id', 'alleleEditPage');

    this.route('edit/alternateNames/:type/:id', 'alternateNamesListEdit');

    this.route('edit/drugGeneInteraction(?:qs)', 'drugGeneNewPage');
    this.route('edit/drugGeneInteraction/:id', 'drugGeneEditPage');

    this.route('edit/drugDrugInteraction(?:qs)', 'drugDrugNewPage');
    this.route('edit/drugDrugInteraction/:id', 'drugDrugEditPage');
    this.route('edit/drugInteraction', 'drugInteractionPage');

    this.route('edit/fdaPgxAssociation', 'fdaPgxAssocPage');
    
    this.route('edit/attachments-list', 'attachmentsListTool');
    this.route('edit/haplotype-submission', 'haplotypeSubmissionTool');
    this.route('edit/pathway/:id/upload/files', 'pathwayUploadFiles');
    this.route('edit/pathway/upload/gpml', 'pathwayUploadGpml');

    this.route('edit/site-alert', 'websiteAnnouncementForm');

    this.route('edit/pathway', 'pathwayEditList');
    this.route('edit/pathway/:id', 'pathwayEditForm');

    this.route('edit/diff/:type/:id(?:qs)', 'diffObject');

    this.route('edit/chemical/:id', 'chemicalEdit');
    this.route('edit/disease/:id', 'diseaseEdit');

    this.route('410', 'gone');

    // old links should be handled by nginx
    // TODO(markwoon): need these here because :cls is case-sensitive right now
    // TODO(markwoon): nginx currently only handles gene/drug/disease/pathway
    this.route('do/serve?objId=:id&objCls=:cls', 'doServeIdClassRedirect');
    this.route('do/serve?objCls=:cls&objId=:id', 'doServeClassIdRedirect');
    this.route('views/index.jsp?objCls=:cls&objId=:id', 'doServeClassIdRedirect');
    this.route('views/index.jsp?objId=:id&objCls=:cls', 'doServeIdClassRedirect');
    this.route('redirect', 'doRedirect');
  },


  /**
   * Override default execute to redirect to /signIn on preview if not already signed in.
   */
  execute(callback, args) {
    if (App.isPreview && !App.currentUser.isLoggedIn) {
      if (!callback || (callback.name !== 'forgotPassword' && callback.name !== 'login')) {
        this.redirect('/user/login');
        return;
      }
    }
    if (callback) {
      callback.apply(this, args);
    }
  },

  doServeIdClassRedirect(id, cls) {
    return this.doServeClassIdRedirect(cls, id);
  },

  doServeClassIdRedirect(cls, id) {
    const type = normalizeType(cls);
    if (type) {
      let finalId = id;
      // maintain mapping for historical reasons
      if (type === 'gene' && id === 'PA35842') {
        finalId = 'PA134865839';
      }
      this.redirect(getBaseUrl(type, finalId), true);
    } else {
      return this.gone();
    }
  },

  doRedirect() {
    if (window.location.search) {
      const {p} = parseSearchParams(window.location.search);
      if (p) {
        this.redirect(p, true);
      }
    }
    return this.unmatched();
  },

  // Home
  home() {
    return this.fetchAndRender(HomePage, {}, {isHome: true});
  },

  genericAccession(id) {
    return this.fetchAndRender(RedirectAccessionId, {id});
  },

  // VIPs
  vipGene(id) {
    return this.fetchAndRender(VipPage, {id});
  },

  labelAnnotation(id, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(LabelPage, {id, loadId});
  },

  guidelineAnnotation(id, section, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(GuidelineAnnotationPage, {id, section: section || 'annotation', loadId});
  },

  literature(id, section, qs) {
    const {newRel} = parseSearchParams(qs);
    return this.fetchAndRender(LiteraturePage, {id, section, newRel});
  },
  
  pmid(id) {
    return this.fetchAndRender(RedirectPmid, {id});
  },

  literatureAnnotation(id) {
    return this.fetchAndRender(RedirectLiteratureAnnotation, {id});
  },

  rsid(id) {
    this.fetchAndRender(RedirectRsid, {id});
  },

  hgnc(id) {
    this.fetchAndRender(RedirectHgnc, {id});
  },

  rxnorm(id) {
    this.fetchAndRender(RedirectRxnorm, {id});
  },

  clinicalAnnotation(id) {
    return this.fetchAndRender(ClinicalAnnotationPage, {id, embedded: false});
  },

  variantAnnotation(id) {
    return this.fetchAndRender(VariantAnnotationPage, {id});
  },

  dataAnnotation(id) {
    if (id === '1448423875') {
      return this.redirect('/pgkbPublications', true);
    }

    return this.fetchAndRender(DataAnnotationPage, {id});
  },

  linkAnnotation(id) {
    return this.fetchAndRender(LinkAnnotationPage, {id});
  },

  chemical(id, section, sectionId, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(ChemicalPage, {id, section, sectionId, loadId});
  },

  combination(id, section) {
    return this.fetchAndRender(CombinationPage, {id, section});
  },

  disease(id, section, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(DiseasePage, {id, section, loadId});
  },

  gene(id, section, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(GenePage, {id, section, loadId});
  },

  haplotype(id, section, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(HaplotypePage, {id, section, loadId});
  },

  pathway(id, section, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(PathwayPage, {id, section, loadId});
  },

  variant(id, section, qs) {
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(VariantPage, {id, section, loadId});
  },

  guideline(id, section = 'overview') {
    return this.fetchAndRender(GuidelinePage, {id, section});
  },


  // Search
  search(qs) {
    const params = parseSearchParams(qs);
    const {pinned, query, type, offset} = params;

    return this.fetchAndRender(SearchPage, {
      query,
      pinned,
      types: asArray(type),
      offset: offset ? parseInt(offset) : 0,
    });
  },

  browseAnnotatedDrugs(qs) {
    const params = parseSearchParams(qs);
    const {prefix} = params;
    return this.fetchAndRender(Browse, {listName: 'annotatedDrugs', prefix});
  },

  browsePediatricSummaryDrugs(qs) {
    const params = parseSearchParams(qs);
    const {prefix} = params;
    return this.fetchAndRender(Browse, {listName: 'pediatricSummaryDrugs', prefix});
  },

  pgkbPublications() {
    return this.fetchAndRender(PgkbPublicationsPage);
  },

  fda() {
    return this.fetchAndRender(Page, {id: 'fda'});
  },

  fdaLabelAnnotations() {
    return this.fetchAndRender(FdaLabelAnnotationsPage, {});
  },

  fdaPgxAssociations() {
    return this.fetchAndRender(FdaPgxAssociationTablePage, {});
  },

  dosingGuidelines() {
    return this.fetchAndRender(GuidelineAnnotationsPage);
  },

  labelAnnotations() {
    return this.fetchAndRender(LabelAnnotationsPage);
  },

  labelApplicationDiff(id) {
    return this.fetchAndRender(LabelApplicationDiff, {applicationId: id});
  },

  clinicalAnnotationsIndex() {
    return this.fetchAndRender(ClinicalAnnotationsPage);
  },
  variantAnnotationsIndex() {
    return this.fetchAndRender(VariantAnnotationsPage);
  },

  pathwayList() {
    return this.fetchAndRender(PathwaysPage);
  },

  vipList() {
    return this.fetchAndRender(VipList, {});
  },

  vipTier2List() {
    return this.fetchAndRender(VipTier2List, {});
  },

  prescribingInfo() {
    return this.fetchAndRender(PrescribingInfo, {});
  },

  testing() {
    return this.fetchAndRender(TestingPage, {});
  },

  genotype(qs) {
    const params = parseSearchParams(qs?.replaceAll('+', '%2B'));
    const {q = '{}'} = params;
    return this.fetchAndRender(GenotypePage, {genoQueryParam: JSON.parse(q)});
  },

  genotypeResults(qs) {
    const params = parseSearchParams(qs?.replaceAll('+', '%2B'));
    const {q = '{}', hideNoRec} = params;
    return this.fetchAndRender(GenotypeResultsPage, {genoQueryParam: JSON.parse(q), hideNoRec: (hideNoRec === 'true' || !hideNoRec)});
  },

  // Sign up, sign in, and related routes
  login(qs) {
    const params = parseSearchParams(qs);
    const nextUrl = params.nextUrl || '';
    const code = params.code || '';
    if (App.currentUser.isLoggedIn) {
      return this.redirect(nextUrl);
    }
    return this.fetchAndRender(Login, {nextUrl, code});
  },

  logout() {
    return this.fetchAndRender(Logout);
  },

  forgotPassword(userId, token) {
    if (userId && token) {
      if (!App.inBrowser) {
        return this.renderStub();
      }
      if (App.currentUser.isLoggedIn) {
        return this.fetchAndRender(AlreadySignedIn);
      }
      return this.fetchAndRender(ResetPassword, {
        userId,
        token,
        message: 'Please set a new password below.',
      });
    } else {
      return this.fetchAndRender(RequestPasswordReset);
    }
  },

  editProfile() {
    if (!App.currentUser) {
      return this.home();
    } else {
      return this.fetchAndRender(EditProfile, {});
    }
  },

  updatePassword() {
    if (!App.currentUser) {
      return this.showLogin();
    } else {
      const {userId} = App.currentUser;
      return this.fetchAndRender(UpdatePassword, {userId});
    }
  },

  // Static Pages

  outreach(section) {
    return this.fetchAndRender(WhatIsPharmgkbPage, {baseUrl: '/whatIsPharmgkb', section});
  },

  pharmacogenomics(section) {
    return this.fetchAndRender(WhatIsPharmacogenomicsPage, {baseUrl: '/whatIsPharmacogenomics', section});
  },

  staticPage(key) {
    return this.fetchAndRender(Page, {id: key});
  },

  about() {
    return this.fetchAndRender(AboutUs);
  },
  ampAllelesToTest() {
    return this.fetchAndRender(AmpAllelesToTestPage);
  },
  feedback(qs) {
    const {message, prevUrl} = parseSearchParams(qs);
    return this.fetchAndRender(FeedbackPage, {message, prevUrl});
  },
  pgxPaperTypes() {
    return this.fetchAndRender(PgxPaperTypesPage, {});
  },
  downloads() {
    return this.fetchAndRender(DownloadsPage, {});
  },
  markdownSyntax() {
    return this.fetchAndRender(MarkdownSyntaxPage);
  },
  versions() {
    return this.fetchAndRender(VersionsPage);
  },
  dataSources() {
    return this.fetchAndRender(DataSourcesPage);
  },
  licensing() {
    return this.staticPage('dataUsagePolicy');
  },

  submission(id) {
    return this.fetchAndRender(SubmissionPage, {id});
  },


  // -- start pediatric links -- //
  pediatricAutomatedAnnotations() {
    const PediatricAutomatedAnnotationsPage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/AutomatedAnnotations'));
    return this.fetchAndRender(PediatricAutomatedAnnotationsPage, {});
  },
  pediatricClinicalAnnotations() {
    const PediatricClinicalAnnotationsPage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/ClinicalAnnotations'));
    return this.fetchAndRender(PediatricClinicalAnnotationsPage, {});
  },
  pediatricDashboard(section) {
    const PediatricDashboardPage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/Dashboard'));
    return this.fetchAndRender(PediatricDashboardPage, {section});
  },
  pediatricGuidelineAnnotations() {
    const PediatricGuidelineAnnotationsPage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/GuidelineAnnotations'));
    return this.fetchAndRender(PediatricGuidelineAnnotationsPage, {});
  },
  pediatricLabelAnnotations() {
    const PediatricLabelAnnotationsPage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/LabelAnnotations'));
    return this.fetchAndRender(PediatricLabelAnnotationsPage, {});
  },
  pediatricLiterature() {
    const PediatricLiteraturePage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/Literature'));
    return this.fetchAndRender(PediatricLiteraturePage, {});
  },
  pediatricVariantAnnotations() {
    const PediatricVariantAnnotationsPage = lazy(() =>
      import(/* webpackChunkName: "pediatric" */'pages/pediatric/VariantAnnotations'));
    return this.fetchAndRender(PediatricVariantAnnotationsPage, {});
  },

  pediatricTags() {
    return this.fetchAndRender(PediatricTagsPage, {});
  },
  // -- end pediatric links -- //


  // -- start edit pages -- //
  curation() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const CuratorDashboard = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation'));
    return this.fetchAndRender(CuratorDashboard);
  },

  development() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DevDashboardPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/dev/Dashboard'));
    return this.fetchAndRender(DevDashboardPage);
  },

  apiUsers(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ApiUserListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/dev/ApiUser/List'));
    const {loadId} = parseSearchParams(qs);
    return this.fetchAndRender(ApiUserListPage, {loadId});
  },

  cpicPage() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const CpicPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/cpic'));
    return this.fetchAndRender(CpicPage);
  },

  litSuggest() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const LitSuggestPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/LitSuggest'));
    return this.fetchAndRender(LitSuggestPage);
  },

  missingClinical() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const MissingClinAnns = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/ClinicalAnnotation/MissingClinAnns'));
    return this.fetchAndRender(MissingClinAnns, {});
  },

  surveyReport() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const SurveyReport = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/SurveyReport'));
    return this.fetchAndRender(SurveyReport, {});
  },

  variantAnnotationsStats() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const VariantAnnotationStats = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/stats/VariantAnnotation'));
    return this.fetchAndRender(VariantAnnotationStats, {});
  },

  gsiStats() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const comp = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/stats/Gsi'));
    return this.fetchAndRender(comp, {});
  },

  curationLiteratureSearch() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const comp = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/literature/Search'));
    return this.fetchAndRender(comp, {});
  },

  listVariantAnnotations() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditVariantAnnotationListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/VariantAnnotation/List'));
    return this.fetchAndRender(EditVariantAnnotationListPage);
  },

  listUnapprovedAnnotations() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const UnapprovedAnnotationListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/CuratorStats/UnapprovedAnnotationList'));
    return this.fetchAndRender(UnapprovedAnnotationListPage);
  },

  listRecentAnnotations() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const RecentAnnotationListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/CuratorStats/RecentAnnotationList'));
    return this.fetchAndRender(RecentAnnotationListPage);
  },

  haplotypeCaReport() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ClinAnnsByHaplotype = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/ClinicalAnnotation/ClinAnnsByHaplotype'));
    return this.fetchAndRender(ClinAnnsByHaplotype);
  },

  duplicateCaReport() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DuplicateClinAnns = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/ClinicalAnnotation/DuplicateClinAnns'));
    return this.fetchAndRender(DuplicateClinAnns);
  },

  clinicalAnnotationNeedsUpdating() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ClinicalAnnotationNeedsUpdateListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/ClinicalAnnotation/NeedsUpdateList'));
    return this.fetchAndRender(ClinicalAnnotationNeedsUpdateListPage);
  },

  clinicalAnnotationScores() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ClinAnnScorePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/ClinicalAnnotation/ScorePage'));
    return this.fetchAndRender(ClinAnnScorePage);
  },

  listClinicalAnnotations() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditClinicalAnnotationListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/ClinicalAnnotation/List'));
    return this.fetchAndRender(EditClinicalAnnotationListPage);
  },

  chemicalStats() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ChemicalStatsPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Chemical/Stats'));
    return this.fetchAndRender(ChemicalStatsPage);
  },

  phenotypeStats() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PhenotypeStatsPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Phenotype/Stats'));
    return this.fetchAndRender(PhenotypeStatsPage);
  },

  missingVariants() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const MissingVariantsPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/nlp/MissingVariants'));
    return this.fetchAndRender(MissingVariantsPage);
  },

  missingVariant(rsid) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const MissingVariantPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/nlp/MissingVariant'));
    return this.fetchAndRender(MissingVariantPage, {rsid});
  },

  hgvsValidation() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const HgvsValidation = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/HgvsValidation'));
    return this.fetchAndRender(HgvsValidation);
  },

  editDataAnnotation(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DataAnnotationEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DataAnnotation/EditPage'));
    return this.fetchAndRender(DataAnnotationEditPage, {id});
  },
  newDataAnnotation(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {source, type, target, litId} = parseSearchParams(qs);
    const targets = asArray(target);
    const DataAnnotationNewPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DataAnnotation/NewPage'));
    return this.fetchAndRender(DataAnnotationNewPage, {source, type, targets, litId});
  },
  listDataAnnotations(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }

    const {type} = parseSearchParams(qs);

    const DataAnnotationListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DataAnnotation/List'));
    return this.fetchAndRender(DataAnnotationListPage, {type});
  },


  editLinkAnnotation(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const LinkAnnotationEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/LinkAnnotation/edit'));
    return this.fetchAndRender(LinkAnnotationEditPage, {id});
  },

  phenoconversionListEdit() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PhenoconversionListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Phenoconversion'));
    return this.fetchAndRender(PhenoconversionListPage, {});
  },
  phenoconversionNew() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PhenoconversionNewPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Phenoconversion/new'));
    return this.fetchAndRender(PhenoconversionNewPage, {});
  },
  phenoconversionEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PhenoconversionEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Phenoconversion/edit'));
    return this.fetchAndRender(PhenoconversionEditPage, {id});
  },


  pageToolEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditPageTool = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/page/EditPageTool'));
    return this.fetchAndRender(EditPageTool, {id});
  },
  pageToolNew(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {pageKey} = parseSearchParams(qs);
    const NewPageTool = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/page/NewPageTool'));
    return this.fetchAndRender(NewPageTool, {pageKey});
  },
  pageListEdit(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {pageKey} = parseSearchParams(qs);

    const PageEditList = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/Page/EditList'));
    return this.fetchAndRender(PageEditList, {pageKey});
  },

  guidelineAnnotationTool(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    if (!id) {
      const NewGuidelineAnnotationPage = lazy(() =>
        import(/* webpackChunkName: "curation" */'pages/edit/GuidelineAnnotation/New'));
      return this.fetchAndRender(NewGuidelineAnnotationPage, {});
    } else {
      const EditGuidelineAnnotationPage = lazy(() =>
        import(/* webpackChunkName: "curation" */'pages/edit/GuidelineAnnotation/Edit'));
      return this.fetchAndRender(EditGuidelineAnnotationPage, {id});
    }
  },

  guidelineList() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const GuidelineListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Guideline/List'));
    return this.fetchAndRender(GuidelineListPage, {});
  },

  guidelineTool(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const GuidelineTool = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Guideline'));
    return this.fetchAndRender(GuidelineTool, {id});
  },

  guidelineAnnotationList() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const GuidelineAnnotationListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/GuidelineAnnotation'));
    return this.fetchAndRender(GuidelineAnnotationListPage, {});
  },


  guidelineAnnotationRecEdit(id, selectedChemicalId) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const RecommendationEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Recommendation'));
    return this.fetchAndRender(RecommendationEditPage, {id, selectedChemicalId});
  },
  labelAnnotationRecEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const RecommendationEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Recommendation'));
    return this.fetchAndRender(RecommendationEditPage, {id});
  },


  labelTool(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    if (id) {
      const EditLabelAnnotationPage = lazy(() =>
        import(/* webpackChunkName: "curation" */'pages/edit/LabelAnnotation/Edit'));
      return this.fetchAndRender(EditLabelAnnotationPage, {id});
    } else {
      const NewLabelAnnotationPage = lazy(() =>
        import(/* webpackChunkName: "curation" */'pages/edit/LabelAnnotation/New'));
      return this.fetchAndRender(NewLabelAnnotationPage, {});
    }
  },

  fdaBiomarker() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    return this.fetchAndRender(FdaBiomarkerPage, {});
  },

  fdaBiomarkerDiff() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    return this.fetchAndRender(FdaBiomarkerDiffPage, {});
  },


  editClinAnn(id = -1) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditClinicalAnnotation = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/ClinicalAnnotation/EditPage'));
    return this.fetchAndRender(EditClinicalAnnotation, {id});
  },


  createVarAnn(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {litId} = parseSearchParams(qs);
    if (!litId) {
      return this.fetchAndRender(ErrorPage, {message: 'You must specify a literature ID'});
    }

    const VariantAnnotationTool = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/VariantAnnotation/Tool'));
    return this.fetchAndRender(VariantAnnotationTool, {litId});
  },

  editVarAnn(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const VariantAnnotationTool = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/VariantAnnotation/Tool'));
    return this.fetchAndRender(VariantAnnotationTool, {id});
  },


  editLinkableResources() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const LinkableResourcesPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/LinkableResources'));
    return this.fetchAndRender(LinkableResourcesPage, {});
  },

  editLiterature(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditLiteraturePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Literature'));
    return this.fetchAndRender(EditLiteraturePage, {id});
  },

  editLiteratureRelatedInPaper(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const RelatedInPaperNewPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Literature/RelatedInPaper/New'));
    return this.fetchAndRender(RelatedInPaperNewPage, {litId: id});
  },

  editLiteratureVariantAnnotations(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditLiteratureVariantAnnotationsPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Literature/VariantAnnotations'));
    return this.fetchAndRender(EditLiteratureVariantAnnotationsPage, {id});
  },


  haplotypeSetListEdit() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditHaplotypeListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Haplotype/List'));
    return this.fetchAndRender(EditHaplotypeListPage, {});
  },

  haplotypeListEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditHaplotypePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Haplotype'));
    return this.fetchAndRender(EditHaplotypePage, {id});
  },

  diplotypeFunctionEdit(id, qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {gene, source} = parseSearchParams(qs);
    const EditDiplotypeFunctionPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Haplotype/DiplotypeFunction'));
    return this.fetchAndRender(EditDiplotypeFunctionPage, {id, gene, source});
  },

  genePhenotypeEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditGenePhenotypePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/GenePhenotype'));
    return this.fetchAndRender(EditGenePhenotypePage, {id});
  },

  genePhenotypeNew(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {gene, source} = parseSearchParams(qs);
    const EditGenePhenotypePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/GenePhenotype'));
    return this.fetchAndRender(EditGenePhenotypePage, {gene: {symbol: gene, id: gene}, source});
  },

  microdictionariesList() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditOntologyResourceListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/OntologyResource/List'));
    return this.fetchAndRender(EditOntologyResourceListPage, {});
  },

  microdictionariesTool(shortName) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditOntologyResourcePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/OntologyResource'));
    return this.fetchAndRender(EditOntologyResourcePage, {resourceId: shortName});
  },

  listPharmgkbTags() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ListPharmgkbTagsPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/PharmgkbTags/List'));
    return this.fetchAndRender(ListPharmgkbTagsPage, {});
  },

  listPharmgkbTagged(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const TaggedWithPharmgkbTagPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/PharmgkbTags/TaggedWith'));
    return this.fetchAndRender(TaggedWithPharmgkbTagPage, {id});
  },

  alternateNamesListEdit(type, id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const AlternateNamesPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/curation/AlternateNames'));
    return this.fetchAndRender(AlternateNamesPage, {id, type});
  },

  drugDrugEditPage(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DrugDrugInteractionEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DrugDrugInteraction/edit'));
    return this.fetchAndRender(DrugDrugInteractionEditPage, {id});
  },
  drugDrugNewPage(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {litId} = parseSearchParams(qs);
    const DrugDrugInteractionNewPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DrugDrugInteraction/new'));
    return this.fetchAndRender(DrugDrugInteractionNewPage, {litId});
  },

  drugGeneEditPage(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DrugGeneInteractionEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DrugGeneInteraction/Edit'));
    return this.fetchAndRender(DrugGeneInteractionEditPage, {id});
  },
  drugGeneNewPage(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const {litId} = parseSearchParams(qs);
    const DrugGeneInteractionNewPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DrugGeneInteraction/New'));
    return this.fetchAndRender(DrugGeneInteractionNewPage, {litId});
  },

  drugInteractionPage() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DrugInteractionPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/DrugInteraction'));
    return this.fetchAndRender(DrugInteractionPage, {});
  },

  fdaPgxAssocPage() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const FdaPgxAssociationPage = lazy(() =>
      import('pages/edit/FdaPgxAssociation'));
    return this.fetchAndRender(FdaPgxAssociationPage, {});
  },

  chemicalEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ChemicalEdit = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Chemical'));
    return this.fetchAndRender(ChemicalEdit, {id});
  },

  diseaseEdit(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DiseaseEdit = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Disease'));
    return this.fetchAndRender(DiseaseEdit, {id});
  },

  importChemical() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const ChemicalImportTool = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Chemical/Import'));

    return this.fetchAndRender(ChemicalImportTool, {});
  },

  importGene() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const NewGenePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Gene/New'));

    return this.fetchAndRender(NewGenePage, {});
  },

  importVariant() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const VariantCreatePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/variant_import/VariantCreatePage'));

    return this.fetchAndRender(VariantCreatePage, {});
  },

  importPhenotype() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PhenotypeImport = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/phenotype/Import'));

    return this.fetchAndRender(PhenotypeImport, {});
  },

  variantEditForm(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const VariantEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/variant_import/VariantEditPage'));
    return this.fetchAndRender(VariantEditPage, {id});
  },

  alleleEditPage(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditAllelePage = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/variant/EditAllelePage'));
    return this.fetchAndRender(EditAllelePage, {id});
  },

  pathwayEditList() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const EditPathwayListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Pathway/List'));
    return this.fetchAndRender(EditPathwayListPage);
  },

  labelEditList() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const LabelListPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/LabelAnnotation'));
    return this.fetchAndRender(LabelListPage);
  },

  pathwayEditForm(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PathwayEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/Pathway/EditPage'));
    return this.fetchAndRender(PathwayEditPage, {id});
  },

  editNonPubMedLiteraturePage() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const NonPubMedLiteratureEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Literature/NonPubMed'));
    return this.fetchAndRender(NonPubMedLiteratureEditPage);
  },

  editLabelDocumentLiteraturePage() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const NonPubMedLiteratureEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Literature/LabelDocument'));
    return this.fetchAndRender(NonPubMedLiteratureEditPage);
  },

  diffObject(type, id, qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const DiffPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/Diff/Page'));
    const params = parseSearchParams(qs);
    return this.fetchAndRender(DiffPage, {type, id, view: params.view ? params.view : 'base'});
  },

  editVip(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const VipEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/vip'));
    return this.fetchAndRender(VipEditPage, {editing: true, id});
  },
  newVip(targetId) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const VipEditPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/vip'));
    return this.fetchAndRender(VipEditPage, {editing: false, targetId});
  },

  attachmentsListTool() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const AttachmentsPage = lazy(() =>
      import(/* webpackChunkName: "curation" */'pages/edit/Attachments'));
    return this.fetchAndRender(AttachmentsPage, {});
  },

  haplotypeSubmissionTool(qs) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const HaplotypeUpload = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/Haplotype/Upload'));
    return this.fetchAndRender(HaplotypeUpload, parseSearchParams(qs));
  },

  pathwayUploadGpml() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PathwayUploadGpml = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/Pathway/UploadGpml'));
    return this.fetchAndRender(PathwayUploadGpml);
  },

  pathwayUploadFiles(id) {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const PathwayUploadFiles = lazy(() =>
      import(/* webpackChunkName: "curation" */'components/Pathway/UploadFiles'));
    return this.fetchAndRender(PathwayUploadFiles, {id});
  },

  websiteAnnouncementForm() {
    if (!App.isPreview) {
      return this.unmatched();
    }
    const WebsiteAnnouncementForm = lazy(() =>
      import(/* webpackChunkName: "curation" */'edit/website_announcement/form'));
    return this.fetchAndRender(WebsiteAnnouncementForm, {});
  },

  // -- end edit pages -- //



  // Trailing Slash Redirect
  // We have chosen that the canonical URL for all of our pages will have no
  // trailing slash, but we don't want trailing slash URLs simply to break.
  // This router handler receives paths with trailing slashes and permanently
  // redirects to the non-trailing slash version.
  trailingSlashRedirect(path) {
    const p = path.replace(/(\/)+$/, '').replace(/^/, '/');
    return this.redirect(p, true);
  },

  reset() {
    return this.fetchAndRender(Reset, {});
  },

  // gone (410)
  gone() {
    return this.fetchAndRender(GoneErrorPage, {location: this.history.fragment});
  },

  // Unmatched (404)
  unmatched() {
    return this.fetchAndRender(NotFound, {location: this.history.fragment});
  },

  serverError(errorMsg) {
    return this.fetchAndRender(ServerError, {msg: errorMsg});
  },
});
