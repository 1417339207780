import {camelCase, find, has, toLower} from 'lodash';
import PropTypes from 'prop-types';

import chemicalIcon from 'images/icons/chemical.svg';
import clinicalAnnotationIcon from 'images/icons/clinicalAnnotation.svg';
import diseaseIcon from 'images/icons/disease.svg';
import geneIcon from 'images/icons/gene.svg';
import guidelineIcon from 'images/icons/guideline.svg';
import haplotypeIcon from 'images/icons/haplotype.svg';
import labelIcon from 'images/icons/label.svg';
import literatureIcon from 'images/icons/literature.svg';
import pathwayIcon from 'images/icons/pathway.svg';
import relationshipIcon from 'images/icons/relationship.svg';
import variantIcon from 'images/icons/variant.svg';
import variantAnnotationIcon from 'images/icons/variantAnnotation.svg';
import vipIcon from 'images/icons/vip.svg';


export const resourceProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  objCls: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
});

export const markdownProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  html: PropTypes.string.isRequired,
});

export const ontologyTermProps = PropTypes.shape({
  id: PropTypes.number,
  resource: PropTypes.string,
  term: PropTypes.string,
  termId: PropTypes.string,
});


export const crossReferenceProps = PropTypes.shape({
  id: PropTypes.number,
  resource: PropTypes.string.isRequired,
  name: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  _url: PropTypes.string.isRequired,
});

export const baseAccessionProps = {
  id: PropTypes.string.isRequired,
  objCls: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
};

export const accessionIdProps = PropTypes.shape(baseAccessionProps);

export const haplotypeProps = PropTypes.shape({
  ...baseAccessionProps,
  hgvs: PropTypes.string,
  gene: accessionIdProps.isRequired,
  functionTerm: ontologyTermProps,
  dpwgFunctionTerm: ontologyTermProps,
  alleles: PropTypes.arrayOf(PropTypes.object),
  copyNumber: PropTypes.string,
  structuralVariation: PropTypes.string,
  original: accessionIdProps,
  reference: PropTypes.bool,
  ampTier: PropTypes.string,
});

export const historyProps = PropTypes.shape({
  id: PropTypes.number,
  date: PropTypes.string,
  description: PropTypes.string,
  majorChange: PropTypes.bool,
  type: PropTypes.string,
  user: PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
  }),
  version: PropTypes.number,
  visibleToAll: PropTypes.bool,
});


export const literatureProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  journal: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  authors: PropTypes.arrayOf(PropTypes.string),
  crossReferences: PropTypes.arrayOf(crossReferenceProps),
});

export const multilinkAnnProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  history: PropTypes.arrayOf(historyProps),
  literature: PropTypes.arrayOf(literatureProps),
  object1s: PropTypes.arrayOf(accessionIdProps),
  object2s: PropTypes.arrayOf(accessionIdProps),
  operator1: PropTypes.string,
  operator2: PropTypes.string,
  related: PropTypes.bool,
  terms: PropTypes.arrayOf(ontologyTermProps),
});

export const dataAnnotationProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  markdown: markdownProps,
  text: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  literature: PropTypes.arrayOf(literatureProps),
});

export const populationEthnicityProps = PropTypes.shape({
  customEthnicity: PropTypes.string,
  ethnicities: PropTypes.arrayOf(PropTypes.string),
  mixed: PropTypes.bool,
  unknown: PropTypes.bool,
  notes: PropTypes.string,
});

export const variantLocationProps = PropTypes.shape({
  id: PropTypes.number,
  buildVersion: PropTypes.string,
  chromosomeId: PropTypes.string,
  chromosomeName: PropTypes.string,
  copyNumber: PropTypes.string,
  diplotypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    allele1: PropTypes.string,
    allele2: PropTypes.string,
  })),
  displayName: PropTypes.string,
  genes: PropTypes.arrayOf(accessionIdProps),
  gpPosition: PropTypes.number,
  haplotypes: PropTypes.arrayOf(accessionIdProps),
  linkedObjects: PropTypes.arrayOf(accessionIdProps),
  refSeqCrossReference: crossReferenceProps,
  refSeqPosition: PropTypes.number,
  rsid: PropTypes.string,
  tagGene: PropTypes.bool,
  type: PropTypes.string,
  variant: accessionIdProps,
});

export const sequenceLocationProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  assembly: PropTypes.string,
  sequence: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    _url: PropTypes.string,
  }).isRequired,
  begin: PropTypes.number.isRequired,
  end: PropTypes.number,
  referenceAllele: PropTypes.string.isRequired,
  variantAlleles: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string.isRequired,
});

export const studyParametersProps = PropTypes.shape({
  alleleOfFreqInCases: PropTypes.string,
  alleleOfFreqInControls: PropTypes.string,
  characteristics: PropTypes.string,
  characteristicsType: PropTypes.string,
  confidenceIntervalStart: PropTypes.number,
  confidenceIntervalStop: PropTypes.number,
  description: PropTypes.string,
  frequencyInCases: PropTypes.number,
  frequencyInControls: PropTypes.number,
  populationEthnicity: populationEthnicityProps,
  populationTypes: PropTypes.arrayOf(ontologyTermProps),
  pvalueOp: ontologyTermProps,
  pvalueString: PropTypes.string,
  ratioStat: PropTypes.number,
  ratioStatType: ontologyTermProps,
  statTests: PropTypes.arrayOf(ontologyTermProps),
  studyType: PropTypes.string,
  studyCases: PropTypes.number,
  studyControls: PropTypes.number,
});

export const variantAnnotationProps = PropTypes.shape({
  id: PropTypes.number,
  alleleGenotype: PropTypes.string,
  assayType: PropTypes.string,
  cell: PropTypes.string,
  comparison: PropTypes.string,
  connWords1: ontologyTermProps,
  connWords2: ontologyTermProps,
  description: PropTypes.string,
  directionOfEffectClear: PropTypes.string,
  drugsConnWord: ontologyTermProps,
  formatVersion: PropTypes.number,
  geneSymbol: PropTypes.string,
  genes: PropTypes.arrayOf(accessionIdProps),
  isAssociated: PropTypes.bool,
  isPlural: PropTypes.bool,
  literature: literatureProps,
  location: variantLocationProps,
  metabolizers1: PropTypes.arrayOf(ontologyTermProps),
  metabolizers2: PropTypes.arrayOf(ontologyTermProps),
  objCls: PropTypes.string.isRequired,
  phenotypeCategories: PropTypes.arrayOf(ontologyTermProps),
  phenotypeLinksConnWord: ontologyTermProps,
  phenotypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    disease: accessionIdProps,
    phenotype: PropTypes.string,
    phenotyepContext: PropTypes.string,
  })),
  polarity: ontologyTermProps,
  populationPhenotypeLinksConnWord: ontologyTermProps,
  populationPhenotypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    disease: accessionIdProps,
    phenotype: PropTypes.string,
    phenotyepContext: PropTypes.string,
  })),
  populationTerm: ontologyTermProps,
  relatedChemicals: PropTypes.arrayOf(accessionIdProps),
  riskAlleleClear: PropTypes.string,
  rsidInPaper: PropTypes.string,
  scoringRationale: PropTypes.string,
  sequenceLocationId: PropTypes.number,
  significance: ontologyTermProps,
  status: PropTypes.string,
  studyParameters: PropTypes.arrayOf(studyParametersProps),
  useForCaScoring: PropTypes.bool,
});

export const labelOccurrenceProps = PropTypes.shape({
  SPL: PropTypes.arrayOf(PropTypes.string),
  object: accessionIdProps,
  phenoCat: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.arrayOf(PropTypes.string),
});

export const fdaPgxAssociationProps = PropTypes.shape({
  drugName: PropTypes.string,
  geneSymbol: PropTypes.string,
  drug: accessionIdProps,
  gene: accessionIdProps,
  affectedSubgroup: PropTypes.string,
  interactionDescription: PropTypes.string,
  source: PropTypes.string,
});

export const reactSelectOptionProps = PropTypes.shape({
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
});

export const formOptionsArray = PropTypes.arrayOf(reactSelectOptionProps);

export const guidelineAnnotationProps = {
  id: PropTypes.string.isRequired,
  recommendation: PropTypes.bool,
  pediatric: PropTypes.bool,
  pediatricMarkdown: markdownProps,
  summaryMarkdown: markdownProps,
  textMarkdown: markdownProps,
  descriptiveVideoId: PropTypes.string,
  userId: PropTypes.string,
  objCls: PropTypes.string,
  nested: PropTypes.bool,
  history: PropTypes.arrayOf(historyProps),
  literature: PropTypes.arrayOf(literatureProps),
  crossReferences: PropTypes.arrayOf(crossReferenceProps),
  sections: PropTypes.shape({
    alleleNotes: PropTypes.arrayOf(dataAnnotationProps),
  }),
  source: PropTypes.string,
  relatedChemicals: PropTypes.arrayOf(accessionIdProps),
};

const Types = {
  chemical: {
    displayType: 'Chemical',
    icon: chemicalIcon,
    url: (id) => `/chemical/${id}`,
    overviewCounts: ['prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'pathway'],
    searchShowAnnCounts: true,
  },
  chromosome: {
    displayType: 'Chromosome',
  },
  clinicalAnnotation: {
    displayType: 'Clinical Annotation',
    url: (id) => `/clinicalAnnotation/${id}`,
    icon: clinicalAnnotationIcon,
  },
  combination: {
    displayType: 'Combination',
    overviewCounts: ['prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'pathway'],
    url: (id) => `/combination/${id}`,
    searchShowAnnCounts: true,
  },
  connection: {
    displayType: 'Connection',
    icon: relationshipIcon,
  },
  control: {
    displayType: 'Control',
  },
  conversion: {
    displayType: 'Conversion',
  },
  crossReference: {
    displayType: 'Cross Reference',
  },
  dataAnnotation: {
    displayType: 'Data Annotation',
    icon: literatureIcon,
    url: (id) => `/dataAnnotation/${id}`,
    propTypes: dataAnnotationProps,
  },
  disease: {
    displayType: 'Disease',
    icon: diseaseIcon,
    url: (id) => `/disease/${id}`,
    overviewCounts: ['clinicalAnnotation', 'pathway'],
  },
  dosingGuidelineAnnotation: {
    displayType: 'Clinical Guideline Annotation',
  },
  entity: {
    displayType: 'Entity',
  },
  fdaPGxAssociation: {
    displayType: 'FDA PGx Association',
    url: () => '/fdaPgxAssociations',
  },
  gene: {
    displayType: 'Gene',
    icon: geneIcon,
    url: (id) => `/gene/${id}`,
    overviewCounts: ['prescribingInfo', 'clinicalAnnotation', 'pathway'],
    searchShowAnnCounts: true,
  },
  guideline: {
    displayType: 'Guideline',
    url: (id) => `/guideline/${id}`,
    icon: guidelineIcon,
  },
  guidelineAnnotation: {
    displayType: 'Guideline Annotation',
    url: (id) => `/guidelineAnnotation/${id}`,
    icon: guidelineIcon,
  },
  haplotype: {
    displayType: 'Haplotype',
    icon: haplotypeIcon,
    url: (id) => `/haplotype/${id}`,
    overviewCounts: ['prescribingInfo', 'clinicalAnnotation'],
    searchShowAnnCounts: true,
  },
  haplotypeSet: {
    displayType: 'Haplotype Set',
  },
  labelAnnotation: {
    displayType: 'Label Annotation',
    url: (id) => `/labelAnnotation/${id}`,
    icon: labelIcon,
  },
  linkAnnotation: {
    displayType: 'Link Annotation',
    url: (id) => `/linkAnnotation/${id}`,
  },
  literature: {
    displayType: 'Literature',
    icon: literatureIcon,
    url: (id) => `/literature/${id}`,
    propTypes: literatureProps,
    overviewCounts: ['variantAnnotation', 'clinicalAnnotation', 'labelAnnotation', 'prescribingInfo'],
    searchKeys: ['resourceId', 'title', 'journal', 'year', 'authors'],
  },
  literatureAnnotation: {
    displayType: 'Literature Annotation',
    url: (id) => `/literatureAnnotation/${id}`,
  },
  multilinkAnnotation: {
    displayType: 'Multilink Annotation',
  },
  ontologyTerm: {
    displayType: 'Ontology Term',
  },
  pathway: {
    displayType: 'Pathway',
    icon: pathwayIcon,
    url: (id) => `/pathway/${id}`,
    overviewCounts: ['pathway', 'literature'],
    searchKeys: ['name', 'categories', 'summary.html'],
  },
  pGxAssociation: {
    displayType: 'PGx Association',
    url: () => '/fdaPgxAssociations',
  },
  phenotype: {
    displayType: 'Disease',
    icon: diseaseIcon,
    url: (id) => `/disease/${id}`,
  },
  prescribingInfo: {
    displayType: 'Prescribing Info',
    icon: guidelineIcon,
  },
  user: {
    displayType: 'User',
  },
  variant: {
    displayType: 'Variant',
    icon: variantIcon,
    url: (id) => `/variant/${id}`,
    overviewCounts: ['clinicalAnnotation', 'variantAnnotation'],
    searchShowAnnCounts: true,
  },
  variantAnnotation: {
    displayType: 'Variant Annotation',
    icon: variantAnnotationIcon,
    currentFormat: 2,
    url: (id) => `/variantAnnotation/${id}`,
  },
  variantDrugAnnotation: {
    displayType: 'Variant Drug Annotation',
    url: (id) => `/variantAnnotation/${id}`,
    icon: variantAnnotationIcon,
  },
  variantFunctionalAssayAnnotation: {
    displayType: 'Variant Functional Assay Annotation',
    url: (id) => `/variantAnnotation/${id}`,
    icon: variantAnnotationIcon,
  },
  variantPhenotypeAnnotation: {
    displayType: 'Variant Phenotype Annotation',
    url: (id) => `/variantAnnotation/${id}`,
    icon: variantAnnotationIcon,
  },
  vip: {
    displayType: 'VIP',
    url: (id) => `/vip/${id}`,
    icon: vipIcon,
  },
  vipGene: {
    displayType: 'VIP',
    icon: vipIcon,
    url: (id) => `/vip/${id}`,
  },
  submission: {
    displayType: 'Submission',
    url: (id) => `/submission/${id}`,
  },
};
export default Types;

const obsoleteTypes = {
  dosingGuideline: 'guidelineAnnotation',
  guideline: 'guidelineAnnotation',
  drug: 'chemical',
  drugLabel: 'labelAnnotation',
  label: 'labelAnnotation',
  fdaPGxAssociation: 'pGxAssociation',
};


export function getBaseUrl(id, type) {
  const ccType = camelCase(type);
  const clz = Types[ccType];
  if (!clz || !has(clz, 'url')) {
    return null;
  }
  return clz.url(id);
}

export function findType(type) {
  if (type) {
    return Types[type] || Types[toLower(type)] || find(Types, (t) => t.displayType && t.displayType === type);
  } else {
    return undefined;
  }
}

export function normalizeType(type) {
  if (!type) {
    return undefined;
  }
  if (Types[type]) {
    return type;
  }
  const ccType = camelCase(type);
  if (Types[ccType]) {
    return ccType;
  }
  if (obsoleteTypes[ccType]) {
    return obsoleteTypes[ccType];
  }

  const keys = Object.keys(Types);
  const lcType = toLower(type);
  for (let x = 0; x < keys.length; x += 1) {
    const t = Types[keys[x]];
    if (t.displayType && toLower(t.displayType) === lcType) {
      return keys[x];
    }
  }
  return undefined;
}

export function getIcon(type) {
  if (Types[type]) {
    return Types[type]?.icon;
  }
  const normalizedType = normalizeType(type);
  return Types?.[normalizedType]?.icon;
}

export function hasIcon(type) {
  return !!getIcon(type);
}

export function isObsoleteType(type) {
  const ccType = camelCase(type);
  return !!obsoleteTypes[ccType];
}
